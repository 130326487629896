<!--
* @description:
* @fileName pageEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-drawer ref="drawerRef" v-model="dialogFormVisible" :before-close="handleClose" custom-class="demo-drawer" :direction="direction" :size="size" :title="title">
    <el-form ref="formRef" label-width="80px" :model="state.form" :rules="state.rules">
      <el-form-item label="渠道名称" prop="channelName">
        <el-input v-model="state.form.channelName" placeholder="请输入渠道名称" />
      </el-form-item>

      <el-form-item label="渠道路径" prop="path">
        <el-input v-model="state.form.path" disabled placeholder="请输入路径" />
      </el-form-item>

      <el-form-item label="渠道备注" prop="remarks">
        <el-input v-model="state.form.remarks" placeholder="请输入渠道备注" />
      </el-form-item>

      <el-form-item label="appKey" prop="appKey">
        <el-select v-model="state.form.appKey">
          <el-option :label="item.name" :value="item.appKey" v-for="(item,index) in state.wcs" :key="index"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="demo-drawer__footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSave">提交</el-button>
    </div>
  </el-drawer>
</template>
<script>
export default { name: 'EditConfigDrawer' }
</script>
<script setup>
import { getCurrentInstance, onMounted, reactive, ref } from 'vue'
import { addExhibitionChannel } from '@/api/exhibitionManage'
const props = defineProps({
  title: {
    type: String,
    default: '购票渠道',
  },
})
const { proxy } = getCurrentInstance()
const emit = defineEmits(['noticeRefresh'])
const dialogFormVisible = ref(false) // 打开或者关闭抽屉
const formRef = ref(false) // 表单数据
const direction = ref('rtl') // 抽屉弹出的方向
const size = ref('40%') //抽屉大小
const state = reactive({
  wcs: [],
  form: {
    relationCode: '',
    type: 'EXHIBITION',
    path: 'subPackage/admission-ticket/buy/exhibition-ticket',
    channelName: '',
    remarks: '',
    appKey: ''
  },
  rules: {
    channelName: [
      { required: true, trigger: 'blur', message: '请输入渠道名称' },
    ],
    path: [{ required: true, trigger: 'blur', message: '请输入路径' }],
    remarks: [{ required: true, trigger: 'blur', message: '请输入渠道备注' }],
    appKey: [{ required: true, trigger: 'change', message: '请输入appKey' }],
  },
})
// 打开
const handleOpen = (row = {}) => {
  state.form = Object.assign(state.form, row)
  state.form.relationCode = state.form.meetingCode
  const { wcs } = JSON.parse(localStorage.getItem('customInfo'))
  state.wcs = wcs
  dialogFormVisible.value = true
}
// 关闭
const handleClose = () => {
  // formRef.value.resetFields()
  handleInit()
  dialogFormVisible.value = false
}
// 初始化传递的参数
const handleInit = () => {
  state.form = {
    relationCode: '',
    type: 'EXHIBITION',
    path: 'subPackage/admission-ticket/buy/exhibition-ticket',
    channelName: '',
    remarks: '',
  }
}
// 提交
const handleSave = () => {
  formRef.value.validate(async (valid) => {
    if (valid) {
      let data = await addExhibitionChannel(state.form)
      proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
      emit('noticeRefresh')
      handleClose()
    }
  })
}

defineExpose({
  handleClose,
  handleOpen,
})
</script>

<style lang="scss" scoped>
.vab-tree-border {
  height: 200px;
  padding: $base-padding;
  overflow-y: auto;
  border: 1px solid #dcdfe6;
  border-radius: $base-border-radius;
}

.demo-drawer__content {
  height: 85vh;
  overflow: auto;
}
.demo-drawer__footer {
  position: fixed;
  right: 20px;
  bottom: 10px;
}
</style>
